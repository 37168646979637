import { useState } from 'react';
import { Button, useTheme, Box } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Avatar from '@mui/material/Avatar';

interface FileProp {
  name: string;
  type: string;
  size: number;
}

const AvatarUpload = ({
  register,
  name,
  setValue,
  defaultValue,
}: {
  register?: any;
  name?: string;
  setValue?: any;
  defaultValue: File | string;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
    onDrop: (acceptedFiles: File[]) => {
      setFiles(acceptedFiles.map((file: File) => Object.assign(file)));
      setValue(name, acceptedFiles[0]);
    },
  });
  const { palette } = useTheme();
  const handleRemoveAllFiles = () => {
    setFiles([]);
    setValue(name, null);
  };

  const img = files.length ? (
    files.map((file: FileProp) => (
      <Box
        key={file.name}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          key={file.name}
          alt={file.name}
          className="single-file-image"
          src={URL.createObjectURL(file as File)}
          style={{ width: '17rem', height: '17rem', borderRadius: '100%' }}
        />
      </Box>
    ))
  ) : (defaultValue as File)?.name ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        key={(defaultValue as File).name}
        alt={(defaultValue as File).name}
        className="single-file-image"
        src={URL.createObjectURL(defaultValue as File)}
        style={{ width: '17rem', height: '17rem', borderRadius: '100%' }}
      />
    </Box>
  ) : defaultValue ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        key={defaultValue as string}
        alt={defaultValue as string}
        className="single-file-image"
        src={defaultValue as string}
        style={{ width: '17rem', height: '17rem', borderRadius: '100%' }}
      />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Avatar
        sx={{ background: palette.primary.main, width: '12rem', height: '12rem' }}
        alt="Upload img"
      />
    </Box>
  );

  return (
    <div>
      <Box {...getRootProps({ className: 'dropzone' })} style={{ cursor: 'pointer' }}>
        <input
          {...getInputProps()}
          id="avatar"
          {...register?.('avatar' as string)}
          name="avatar"
        />
        {img}
      </Box>
      {files.length ? (
        <Box display="flex" justifyContent="end">
          <Button color="error" variant="outlined" onClick={handleRemoveAllFiles}>
            Remove
          </Button>
        </Box>
      ) : null}
    </div>
  );
};

export default AvatarUpload;
