/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-undef */

import { Box, Grid, Typography, Divider, Tooltip, Slide } from '@mui/material';

import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { IDriver } from 'src/models/IDriver';
import useMediaQuery from '@mui/material/useMediaQuery';
const DriverInfo = ({ driver }: { driver: IDriver }) => {
  const isDesktop = useMediaQuery('(min-width:600px)');

  return (
    <Grid
      item
      xs={12}
      margin={1}
      sx={{
        maxWidth: '-webkit-fill-available',
      }}
    >
      <Slide direction="up" in timeout={500}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            bgcolor: 'rgba(255, 255, 255, 1)',
            borderRadius: '2%',
            padding: isDesktop ? '10px' : 0,
            margin: isDesktop ? '1rem' : 0,
          }}
        >
          <Typography gutterBottom variant="h4" margin="10px">
            <Box display="flex" flexDirection="row" alignItems="center" margin={1}>
              <Tooltip title="Name" placement="left" arrow>
                <PersonIcon sx={{ marginRight: '5px' }} color="primary" />
              </Tooltip>
              <Typography variant="h4" />
              <Tooltip title="Verified" placement="right-start" arrow>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="h4" marginLeft={2}>
                    Driver Name :
                  </Typography>
                  <Typography variant="h4" marginLeft={9}>
                    {driver?.name}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          </Typography>{' '}
          <Divider />
          <Typography gutterBottom variant="h4" margin="10px">
            <Box display="flex" flexDirection="row" alignItems="center" margin={1}>
              <Tooltip title="Category" placement="left" arrow>
                <DashboardIcon sx={{ marginRight: '5px' }} color="primary" />
              </Tooltip>
              <Typography variant="h4" marginLeft={2}>
                Handled Category :
              </Typography>
              <Typography variant="h4" marginLeft={4}>
                {driver?.handledCategory}
              </Typography>
            </Box>
          </Typography>
          <Divider />
          <Typography gutterBottom variant="h4" margin="10px">
            <Box display="flex" flexDirection="row" alignItems="center" margin={1}>
              <Tooltip title="Cities" placement="left" arrow>
                <PlaceIcon sx={{ marginRight: '5px' }} color="primary" />
              </Tooltip>
              <Typography variant="h4" marginLeft={2}>
                Driver Region :
              </Typography>
              <Typography variant="h4" marginLeft={8}>
                {driver?.handledCities?.map((e) => e.city).join(',')}
              </Typography>
            </Box>
          </Typography>
          <Divider />
          <Typography gutterBottom variant="h4" margin="10px">
            <Box display="flex" flexDirection="row" alignItems="center" margin={1}>
              <Tooltip title="Phone" placement="left" arrow>
                <PhoneIcon
                  color="primary"
                  sx={{
                    marginRight: '5px',
                  }}
                />
              </Tooltip>
              <Typography variant="h4" marginLeft={2}>
                Phone Number :
              </Typography>
              <Typography variant="h4" marginLeft={7}>
                {driver?.phoneNumber}
              </Typography>
            </Box>
          </Typography>
          <Divider />
        </Box>
      </Slide>
    </Grid>
  );
};

export default DriverInfo;
