import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import ResponseWrapperType from 'src/types/IResponse';
import { IDriver, IDriverCredential } from 'src/models/IDriver';
import { GET_DRIVER_BY_ID } from 'src/graphql/drivers/driversQueries';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ICityModel } from 'src/models/cityType';
import { updateDriver } from 'src/services/drivers';
import { toast } from 'react-toastify';
import { GET_CITIES } from 'src/graphql/cities/citiesQueries';

const useDriverProfile = () => {
  const { id } = useParams();

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [expandedOrderId, setExpandedOrderId] = useState('');

  const [driver, setDriver] = useState<IDriver>();
  const initialDisplay = 'details';
  const [currentDisplay, setCurrentDisplay] = useState<string>(initialDisplay);

  const [defaultValues, setDriverToUpdate] = useState<IDriverCredential & { _id: string }>({
    ...driver,
    password: driver?.plainPasssword,
  });

  const [isRegisterDriverLoading, setIsRegisterDriverLoading] = useState(false);
  const [credentials, setCredentials] = useState<IDriverCredential>(
    defaultValues || ({} as IDriverCredential),
  );

  const [buyerDetailsVisibility, setBuyerDetailsVisibility] = useState(false);

  const formMethods = useForm<IDriverCredential>({ defaultValues: credentials });
  const { handleSubmit, watch, reset, register, control, setValue } = formMethods;
  const { data: driverData } = useQuery<
    ResponseWrapperType<'getDriverById', 'driver', IDriver>
  >(GET_DRIVER_BY_ID, {
    variables: { driverId: id },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { plainPasssword, ...rest } = data?.getDriverById?.driver;

      setDriver(driverData?.getDriverById?.driver);
      setDriverToUpdate({ ...rest, password: plainPasssword });
      setCredentials({ ...rest, password: plainPasssword });
      reset({ ...rest, password: plainPasssword });
    },
  });

  const { data: citiesData } = useQuery<Record<'getAllCities', { cities: ICityModel[] }>>(
    GET_CITIES,
    {
      fetchPolicy: 'network-only',
    },
  );

  const cities = citiesData?.getAllCities?.cities;

  const [displayUpdatesCard, setDisplayUpdatesCard] = useState(false);

  const handleSubmitCredentials = () => {
    setIsRegisterDriverLoading(true);
    updateDriver(driver._id, credentials)
      .then((res) => {
        toast(res.message, { type: 'success' });
        setIsRegisterDriverLoading(false);
        setDriver({
          ...credentials,
          plainPasssword: credentials?.password,
        } as unknown as IDriver);
        setDisplayUpdatesCard(false);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
        setIsRegisterDriverLoading(false);
      });
  };

  const handleOpenDriversModal = () => {
    setDisplayUpdatesCard(true);
  };

  const handleCloseDriversModal = () => {
    setDisplayUpdatesCard(false);
  };

  const handleNextClick = () => {
    const currentWatchedValues = watch();
    let cleanedUpWatchValues;

    Object.keys(currentWatchedValues).forEach((key) => {
      if (currentWatchedValues[key]) {
        cleanedUpWatchValues = { ...cleanedUpWatchValues, [key]: currentWatchedValues[key] };
      }
    });
    setCredentials((prev) => ({ ...prev, ...cleanedUpWatchValues }));
    reset({ ...credentials, ...cleanedUpWatchValues });
  };
  const handleOrderClick = (orderId: string) => {
    if (expandedOrderId === orderId) {
      setExpandedOrderId('');
    } else {
      setExpandedOrderId(orderId);
    }
  };

  return {
    driver,
    defaultValues,
    credentials,
    formMethods,
    handleSubmit,
    register,
    control,
    setValue,
    isRegisterDriverLoading,
    setDisplayUpdatesCard,
    displayUpdatesCard,
    cities,
    handleNextClick,
    setCredentials,
    handleSubmitCredentials,
    handleCloseDriversModal,
    handleOpenDriversModal,
    currentDisplay,
    setCurrentDisplay,
    initialDisplay,
    openDetailModal,
    buyerDetailsVisibility,
    setBuyerDetailsVisibility,
    handleOrderClick,
    expandedOrderId,
  };
};

export default useDriverProfile;
