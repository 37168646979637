/* eslint-disable max-lines */
import { gql } from '@apollo/client';

export const GET_DRIVERS = gql`
  query (
    $limit: Int
    $page: Int
    $filter: String
    $sort: Int
    $sortBy: String
    $status: String
  ) {
    getAllDrivers(
      limit: $limit
      page: $page
      filter: $filter
      sort: $sort
      sortBy: $sortBy
      status: $status
    ) {
      status
      message
      drivers {
        page
        hasNextPage
        totalPages
        totalItems
        items {
          pickUpOrders {
            orderItemId {
              items {
                _id
                quantity
                orderItemProductStatus {
                  status
                  createdAt
                }
                product {
                  _id
                  productName
                  productImages
                  productEIN
                  productPrice
                }
                variant {
                  variantEIN
                  variantPrice
                  variantImages
                  _id
                  variantName
                }
                notes
              }
              orderItemCode
              orderItemStatus {
                status
                createdAt
              }
              shopId {
                _id
                shopName
                shopLogo
                shopPickupAddresses {
                  pickUpCity
                  _id
                  pickUpAddress
                  pickUpContactLandNumber {
                    number
                    isVerified
                  }
                  pickUpContactMobileNumber {
                    number
                    isVerified
                  }
                }
              }
            }
            pickupNotes
            pickupDate
          }
          _id
          avatar
          name
          updatedAt
          createdAt
          phoneNumber
          handledCities {
            insideAreas
            remoteAreas
            city
          }
          handledCategory
          plainPasssword
          deliveryOrders {
            deliveryNotes
            deliveryDate
            deliveryNotes
            orderItemId {
              items {
                _id
                quantity
                orderItemProductStatus {
                  status
                  createdAt
                }
                product {
                  _id
                  productName
                  productImages
                  productEIN
                  productPrice
                }
                variant {
                  variantEIN
                  variantPrice
                  variantImages
                  _id
                  variantName
                }
                notes
              }
              orderItemCode
              orderItemStatus {
                status
                createdAt
              }
              shopId {
                _id
                shopName
                shopLogo
                shopPickupAddresses {
                  pickUpCity
                  _id
                  pickUpAddress
                  pickUpContactLandNumber {
                    number
                    isVerified
                  }
                  pickUpContactMobileNumber {
                    number
                    isVerified
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DRIVERS_BY_CITY = gql`
  query ($cityName: String) {
    getDriversByCity(cityName: $cityName) {
      status
      message
      drivers {
        _id
        name
        avatar
        userId {
          _id
        }
        phoneNumber
        handledCities {
          city
        }
        pickUpOrders {
          orderItemId {
            _id
            totalCost
            estimatedDeliveryTime
            pickupAddress {
              pickUpCity
              pickUpAddress
            }
            items {
              _id
              quantity
              orderItemProductStatus {
                status
                createdAt
              }
              product {
                _id
                productName
                productImages
                productEIN
                productPrice
              }
              variant {
                variantEIN
                variantPrice
                variantImages
                _id
                variantName
              }
              notes
            }
            orderItemCode
            orderItemStatus {
              status
              createdAt
            }
            shopId {
              _id
              shopName
              shopLogo
              shopPickupAddresses {
                pickUpCity
                _id
                pickUpAddress
                pickUpContactLandNumber {
                  number
                  isVerified
                }
                pickUpContactMobileNumber {
                  number
                  isVerified
                }
              }
            }
          }
        }
        deliveryOrders {
          orderItemId {
            _id
            totalCost
            estimatedDeliveryTime
            pickupAddress {
              pickUpCity
              pickUpAddress
            }
            items {
              _id
              quantity
              orderItemProductStatus {
                status
                createdAt
              }
              product {
                _id
                productName
                productImages
                productEIN
                productPrice
              }
              variant {
                variantEIN
                variantPrice
                variantImages
                _id
                variantName
              }
              notes
            }
            orderItemCode
            orderItemStatus {
              status
              createdAt
            }
            shopId {
              _id
              shopName
              shopLogo
              shopPickupAddresses {
                pickUpCity
                _id
                pickUpAddress
                pickUpContactLandNumber {
                  number
                  isVerified
                }
                pickUpContactMobileNumber {
                  number
                  isVerified
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_DRIVER = gql`
  query ($driverId: ID) {
    deleteDriver(driverId: $driverId) {
      status
      message
    }
  }
`;

export const GET_DRIVER_BY_ID = gql`
  query ($driverId: ID) {
    getDriverById(driverId: $driverId) {
      driver {
        _id
        notes
        status
        avatar
        name
        phoneNumber
        plainPasssword
        handledCategory
        pickUpOrders {
          pickupNotes
          pickupDate
          orderItemId {
            _id
            shipmentQrCode
            totalCost
            estimatedDeliveryTime
            orderItemCode

            pickupAddress {
              pickUpCity
              pickUpAddress
            }
            items {
              _id
              quantity
              orderItemProductStatus {
                status
                createdAt
              }
              product {
                _id
                productName
                productImages
                productEIN
                productPrice
              }
              variant {
                variantEIN
                variantPrice
                variantImages
                _id
                variantName
              }
              notes
            }
            orderItemStatus {
              status
              createdAt
            }
            shopId {
              _id
              shopName
              shopLogo
              shopContactDetails {
                phoneNumber
              }
              shopPickupAddresses {
                pickUpCity
                _id
                pickUpAddress
                pickUpContactLandNumber {
                  number
                  isVerified
                }
                pickUpContactMobileNumber {
                  number
                  isVerified
                }
              }
            }
            billingAddress {
              fullName
              phone
              house
              address
              city
              area
              country
              mapLocation {
                coordinates
                type
                description
              }
            }
            createdAt
            updatedAt
          }
        }
        userId {
          userName
          userEmail
        }
        courrierId {
          _id
        }

        handledCities {
          insideAreas
          remoteAreas
          city
        }
        deliveryOrders {
          deliveryNotes
          deliveryDate
          orderItemId {
            _id
            shipmentQrCode
            totalCost
            estimatedDeliveryTime
            orderItemCode

            pickupAddress {
              pickUpCity
              pickUpAddress
            }
            items {
              _id
              quantity
              orderItemProductStatus {
                status
                createdAt
              }
              product {
                _id
                productName
                productImages
                productEIN
                productPrice
              }
              variant {
                variantEIN
                variantPrice
                variantImages
                _id
                variantName
              }
              notes
            }
            orderItemStatus {
              status
              createdAt
            }
            shopId {
              _id
              shopName
              shopLogo
              shopContactDetails {
                phoneNumber
              }
              shopPickupAddresses {
                pickUpCity
                _id
                pickUpAddress
                pickUpContactLandNumber {
                  number
                  isVerified
                }
                pickUpContactMobileNumber {
                  number
                  isVerified
                }
              }
            }
            billingAddress {
              fullName
              phone
              house
              address
              city
              area
              country
              mapLocation {
                coordinates
                type
                description
              }
            }
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
      }
      status
      message
    }
  }
`;
