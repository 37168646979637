/* eslint-disable max-lines */
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { styled } from '@mui/material/styles';
import { StepIconProps } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
interface IStep {
  label: string;
  isOptional?: boolean;
  component: JSX.Element;
}

const HorizontalLinearStepper = ({
  steps,
  handleNextClick,
  submitButton,
  isNextButtonDisabled,
}: {
  steps: IStep[];
  handleNextClick: () => void;
  submitButton: JSX.Element;
  isNextButtonDisabled?: boolean;
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepOptional = (index: number) => steps[index].isOptional;

  const isStepSkipped = (step: number) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    handleNextClick();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());

      newSkipped.add(activeStep);

      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(94deg, rgba(0,7,36,0.773546918767507) 0%, rgba(128,0,255,1) 34%, rgba(192,0,255,1) 100%);',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(94deg, rgba(0,7,36,0.773546918767507) 0%, rgba(128,0,255,1) 34%, rgba(192,0,255,1) 100%);',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient(94deg, rgba(0,7,36,0.773546918767507) 0%, rgba(128,0,255,1) 34%, rgba(192,0,255,1) 100%);',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient(94deg, rgba(0,7,36,0.773546918767507) 0%, rgba(128,0,255,1) 34%, rgba(192,0,255,1) 100%);',
    }),
  }));

  const ColorlibStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <GroupAddIcon />,
      2: <SettingsIcon />,
      3: <VideoLabelIcon />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        sx={{ paddingTop: 0, background: 'none' }}
        connector={<ColorlibConnector />}
      >
        {steps.map(({ label }, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button variant="outlined" onClick={handleReset}>
              Reset
            </Button>
          </Box>
        </>
      ) : (
        <>
          {steps[activeStep].component}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, alignItems: 'flex-start' }}>
            {activeStep !== 0 && (
              <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }} variant="outlined">
                <ArrowBackIosNewIcon />
              </Button>
            )}
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }} variant="outlined">
                Skip
              </Button>
            )}

            {activeStep === steps.length - 1 ? (
              submitButton
            ) : (
              <Button onClick={handleNext} variant="outlined" disabled={isNextButtonDisabled}>
                <ArrowForwardIosIcon />{' '}
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default HorizontalLinearStepper;
