import { useQuery } from '@apollo/client';
import { FC, useState, createContext } from 'react';
import { VERIFY_TOKEN } from 'src/graphql/courrier/courrierQueries';
import { IUser } from 'src/models/IUser';
type UserContextType = {
  currentCourrier: IUser;
  handleUpdateCurrentCourrier: ({ courrier }: IUser) => void;
};

export const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider: FC = ({ children }) => {
  const [currentCourrier, setCurrentCourrier] = useState<IUser>(null);

  const handleUpdateCurrentCourrier = (courrier: IUser) => {
    setCurrentCourrier(courrier);
  };

  useQuery<{ verifyToken: IUser }>(VERIFY_TOKEN, {
    onCompleted: (res) => {
      setCurrentCourrier(res.verifyToken);
    },
  });

  return (
    <UserContext.Provider value={{ currentCourrier, handleUpdateCurrentCourrier }}>
      {children}
    </UserContext.Provider>
  );
};
