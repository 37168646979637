import { useContext } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  Typography,
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import Home from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';

// type HeaderWrapperType = StyledComponent<SystemProps<Theme> & {
//   children?: React.ReactNode;
//   component?: React.ElementType<any>;
//   ref?: React.Ref<unknown>;
//   sx?: SxProps<Theme>;
// }>

const HeaderWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'sidebarToggle',
})<{ sidebarToggle?: boolean }>(
  ({ theme, sidebarToggle }) => `  height: ${theme.header.height};
color: ${theme.header.textColor};
padding: ${theme.spacing(0, 2)};
right: 0;
z-index: 6;
background-color: ${alpha(theme.header.background, 0.95)};
backdrop-filter: blur(3px);
position: fixed;
justify-content: space-between;
width: 100%;
@media (min-width: ${theme.breakpoints.values.lg}px) {
    left: ${sidebarToggle ? theme.sidebar.width : 0};
    width: auto;
}`,
);

const Header = () => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <HeaderWrapper
      sidebarToggle={sidebarToggle}
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15,
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2,
              )}, 0px 5px 22px -4px ${alpha(theme.colors.alpha.black[100], 0.1)}`,
      }}
    >
      <Box
        component="span"
        sx={{
          ml: 2,
        }}
      >
        <Tooltip arrow title="Toggle Menu">
          <IconButton color="primary" onClick={toggleSidebar}>
            {!sidebarToggle ? (
              <MenuTwoToneIcon fontSize="small" />
            ) : (
              <CloseTwoToneIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <IconButton onClick={() => navigate('orders?status=Confirmed')}>
          <Home color="primary" />{' '}
          <Typography variant="body1" color="secondary" mx={1}>
            Home
          </Typography>
        </IconButton>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIosIcon color="primary" />{' '}
          <Typography variant="body1" color="secondary" mx={1}>
            Back
          </Typography>
        </IconButton>
      </Box>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <HeaderMenu />
      </Stack>
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <HeaderUserbox />
      </Box>
    </HeaderWrapper>
  );
};

export default Header;
