import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Card,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
  Box,
} from '@mui/material';
import { useState } from 'react';
import { ICityModel } from 'src/models/cityType';
import { HandledCities, IDriverCredential } from 'src/models/IDriver';

const CityCard = ({
  city,
  credentials,
  setCredentials,
}: {
  city: ICityModel;
  credentials: IDriverCredential;
  setCredentials: React.Dispatch<React.SetStateAction<IDriverCredential>>;
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const foundCity = credentials.handledCities?.find((el) => el.city === city.cityName);

  const isAreaSelected = (area: string) => foundCity?.insideAreas.includes(area);
  const handleClickArea = (area: string) => {
    if (isAreaSelected(area))
      setCredentials((prev) => {
        const cleanedUpCity = {
          ...foundCity,
          insideAreas: foundCity.insideAreas.filter((e) => e !== area),
        };

        return cleanedUpCity.insideAreas.length
          ? {
              ...prev,
              handledCities: [
                ...prev.handledCities.filter((e) => e.city !== city.cityName),
                cleanedUpCity,
              ],
            }
          : {
              ...prev,
              handledCities: prev.handledCities.filter((e) => e.city !== city.cityName),
            };
      });
    else if (foundCity) {
      foundCity.insideAreas.push(area);
      setCredentials((prev) => ({
        ...prev,
        handledCities: [
          ...prev.handledCities.filter((e) => e.city !== city.cityName),
          foundCity,
        ],
      }));
    } else {
      const newCity: HandledCities = {
        city: city.cityName,
        insideAreas: [area],
        remoteAreas: [],
      };

      setCredentials((prev) => ({
        ...prev,
        handledCities: prev.handledCities ? [...prev.handledCities, newCity] : [newCity],
      }));
    }
  };

  const { palette } = useTheme();

  return (
    <Card sx={{ p: 2, position: 'relative', backgroundColor: '#f1f1f1', mb: '2rem' }}>
      <ListItemButton
        onClick={handleClick}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="body1"> {city.cityName} </Typography>
          <Typography variant="subtitle1">
            {' '}
            Selected Areas: {foundCity?.insideAreas.length || 0}
          </Typography>
        </Box>{' '}
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {[...city.insideAreas, ...city.remoteAreas].map((area) => (
            <ListItemButton
              key={area.areaName}
              sx={{
                pl: 4,
                backgroundColor: isAreaSelected(area.areaName) ? palette.primary.light : '',
                color: isAreaSelected(area.areaName) ? 'white' : '',
                my: '0.5rem',
              }}
              onClick={() => handleClickArea(area.areaName)}
            >
              <ListItemText primary={area.areaName} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </Card>
  );
};

export default CityCard;
