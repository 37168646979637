/* eslint-disable max-lines */
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import ManagementDriverProfile from './content/applications/Users/drivers/DriverProfile';

// eslint-disable-next-line react/display-name
const Loader = (Component) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const TermsAndConditions = Loader(lazy(() => import('src/content/pages/termsAndConditions')));
// const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

// Applications

// const ManagementUserSettings = Loader(
//   lazy(() => import('src/content/applications/Users/settings')),
// );
const SignIn = Loader(lazy(() => import('src/content/SignIn')));
const OrdersTabsPage = Loader(
  lazy(() => import('./content/applications/Orders/MainOrdersPage/OrdersTabsPage')),
);
const FinancesOverview = Loader(
  lazy(() => import('./content/applications/Orders/FinancesOverview')),
);
// const FeesManagement = Loader(
//   lazy(() => import('./content/applications/Orders/FeesManagement')),
// );
const OrderDetails = Loader(
  lazy(() => import('./content/applications/Orders/OrderDetails/OrderDetailsContainer')),
);
// const WithdrawalsTabsPage = Loader(
//   lazy(() => import('./content/applications/Orders/Withdrawals/WithdrawalsTabsPage')),
// );

const Messenger = Loader(lazy(() => import('src/content/applications/Messenger')));

const UserProfile = Loader(lazy(() => import('src/content/applications/Users/profile')));
const DriversTable = Loader(
  lazy(() => import('src/content/applications/Users/drivers/DriversTable')),
);
const UserSettings = Loader(lazy(() => import('src/content/applications/Users/settings')));

// Components

const Buttons = Loader(lazy(() => import('src/content/pages/Components/Buttons')));
const Modals = Loader(lazy(() => import('src/content/pages/Components/Modals')));
const Accordions = Loader(lazy(() => import('src/content/pages/Components/Accordions')));
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(lazy(() => import('src/content/pages/Components/Badges')));
const Tooltips = Loader(lazy(() => import('src/content/pages/Components/Tooltips')));
const Avatars = Loader(lazy(() => import('src/content/pages/Components/Avatars')));
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="management/orders?status=Confirmed" replace />,
      },
      {
        path: 'signin',
        element: <SignIn />,
      },
      {
        path: 'unauthorized',
        element: <Status500 />,
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />,
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />,
          },
          {
            path: '404',
            element: <Status404 />,
          },
          {
            path: '500',
            element: <Status500 />,
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />,
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />,
          },
        ],
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="crypto" replace />,
      },
      {
        path: 'messenger',
        element: <Messenger />,
      },
    ],
  },
  {
    path: 'terms-and-conditions',
    element: <SidebarLayout />,
    children: [{ path: '', element: <TermsAndConditions /> }],
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="users" replace />,
      // },
      {
        path: 'drivers',
        element: <DriversTable />,
      },

      // {
      //   path: 'unvalid-users',
      //   element: <UnverifiedUsersContainer />,
      // },
      // {
      //   path: 'products-options',
      //   element: <ProductOptions />,
      // },
      // {
      //   path: 'admin',
      //   element: <ManagementUserSettings />,
      // },
      // {
      //   path: 'products',
      //   element: <ProductsTable />,
      // },
      // {
      //   path: 'product-details/:id',
      //   element: <ProductDetails />,
      // },
      {
        path: 'orders',
        element: <OrdersTabsPage />,
      },
      {
        path: 'order-details/:id',
        element: <OrderDetails />,
      },
      // {
      //   path: 'costumers',
      //   element: <CostumersTable />,
      // },
      {
        path: 'finances',
        children: [
          { path: 'overview', element: <FinancesOverview /> },
          // { path: 'orders', element: <OrdersTabsPage /> },
          // { path: 'fees', element: <FeesManagement /> },
          // { path: 'withdrawals', element: <WithdrawalsTabsPage /> },
          // { path: 'refunds', element: <RefundsTabsPage /> },
        ],
      },
      // {
      //   path: 'suppliers',
      //   children: [
      //     { path: 'all', element: <VerifiedSuppliersTable /> },
      //     { path: 'requests', element: <SuppliersTabsPage /> },
      //   ],
      // },
      // {
      //   path: 'admins',
      //   children: [
      //     { path: 'all', element: <AdminsTable /> },
      //     { path: 'add', element: <AddEmployeesFormPage /> },
      //     { path: 'roles', element: <AdminRolesPage /> },
      //   ],
      // },
      // {
      //   path: 'shops',
      //   element: <ShopsTable />,
      // },
      // {
      //   path: 'shops/details/:id',
      //   element: <ShopDetails />,
      // },

      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />,
          },
          {
            path: 'details',
            element: <UserProfile />,
          },
          {
            path: 'details/:id',
            element: <ManagementDriverProfile />,
          },
          {
            path: 'settings',
            element: <UserSettings />,
          },
        ],
      },
    ],
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />,
      },
      {
        path: 'buttons',
        element: <Buttons />,
      },
      {
        path: 'modals',
        element: <Modals />,
      },
      {
        path: 'accordions',
        element: <Accordions />,
      },
      {
        path: 'tabs',
        element: <Tabs />,
      },
      {
        path: 'badges',
        element: <Badges />,
      },
      {
        path: 'tooltips',
        element: <Tooltips />,
      },
      {
        path: 'avatars',
        element: <Avatars />,
      },
      {
        path: 'cards',
        element: <Cards />,
      },
      {
        path: 'forms',
        element: <Forms />,
      },
    ],
  },
];

export default routes;
