import { useRef, useState, useContext } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { UserContext } from 'src/contexts/UserContext';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`,
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`,
);

const HeaderUserbox = () => {
  const navigate = useNavigate();

  const { currentCourrier } = useContext(UserContext);
  const user = {
    avatar: '/static/images/avatars/defaultAvatar.png',
    jobtitle: 'Courrier',
  };

  const ref = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const handleSignOut = () => {
    localStorage.removeItem('token');
    navigate('/signin');
  };

  return currentCourrier ? (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <Avatar
          sx={{ bgcolor: 'rgba(128, 0, 255, 0.4)' }}
          variant="rounded"
          alt={currentCourrier?.userName}
          src={currentCourrier?.courrier?.logo || user.avatar}
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {currentCourrier?.courrier?.companyName || currentCourrier?.userName}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">{user.jobtitle}</UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar
            sx={{ bgcolor: 'rgba(128, 0, 255, 0.4)' }}
            variant="rounded"
            alt={currentCourrier?.userName}
            src={currentCourrier?.courrier?.logo || user.avatar}
          />
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {currentCourrier?.courrier?.companyName || currentCourrier?.userName}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">{user.jobtitle}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem
            button
            to="/management/profile/details"
            onClick={handleClose}
            component={NavLink}
          >
            <AccountCircleIcon fontSize="small" />
            <ListItemText primary="My Profile" />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleSignOut}>
            <ExitToAppIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  ) : (
    <></>
  );
};

export default HeaderUserbox;
