/* eslint-disable max-lines */
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, MenuItem, Grid, List, ListSubheader, useMediaQuery } from '@mui/material';
import { Control } from 'react-hook-form';
import CityCard from 'src/components/CityCard';
import ControlledInput from 'src/components/Form/ControlledInput';
import ControlledSelect from 'src/components/Form/ControlledSelect';
import HorizontalLinearStepper from 'src/components/HorizontalLinearStepper';
import AvatarUpload from 'src/components/ImageUpload';
import { ICityModel } from 'src/models/cityType';
import { IDriverCredential } from 'src/models/IDriver';

interface IDriverForm {
  cities: ICityModel[];
  credentials: IDriverCredential;
  setCredentials: React.Dispatch<React.SetStateAction<IDriverCredential>>;
  handleSubmitCredentials: (event) => void;
  isRegisterDriverLoading: boolean;
  handleNextClick: () => void;
  register: unknown;
  control: Control<IDriverCredential>;
  setValue: unknown;
  isUpdate: boolean;
}
const DriverForm = ({
  isUpdate,
  register,
  credentials,
  setCredentials,
  handleSubmitCredentials,
  isRegisterDriverLoading,
  handleNextClick,
  control,
  setValue,
  cities,
}: IDriverForm) => {
  const isDesktop = useMediaQuery('(min-width:600px)');

  return (
    // const hanlingSizes: HandledSizes[] = [
    //   {
    //     description: 'small',
    //     dimensions: { height: '20 cm', length: '20 cm', weight: '15 kg', width: '20 cm' },
    //     price: { insideAreas: 15, remoteAreas: 25 },
    //   },
    //   {
    //     description: 'medium',
    //     dimensions: { height: '35 cm', length: '35 cm', weight: '20 kg', width: '35 cm' },
    //     price: { insideAreas: 20, remoteAreas: 35 },
    //   },
    //   {
    //     description: 'large',
    //     dimensions: { height: '45 cm', length: '45 cm', weight: '30 kg', width: '45 cm' },
    //     price: { insideAreas: 30, remoteAreas: 40 },
    //   },
    // ];

    <Grid
      container
      spacing={2}
      px={3}
      pt={5}
      display="flex"
      alignItems="center"
      style={{ width: '55rem', ...(!isDesktop && { margin: 0 }) }}
      xs={12}
    >
      <HorizontalLinearStepper
        submitButton={
          <LoadingButton
            onClick={handleSubmitCredentials}
            loading={isRegisterDriverLoading}
            type="submit"
            variant="contained"
            sx={{ mb: '4rem' }}
          >
            {`${isUpdate ? 'Update' : 'Add '} Driver`}
          </LoadingButton>
        }
        handleNextClick={handleNextClick}
        steps={[
          {
            label: 'Driver Info',
            component: (
              <>
                <Grid
                  container
                  spacing={2}
                  px={3}
                  pt={5}
                  sx={{ ...(!isDesktop && { margin: 0, padding: 0 }) }}
                  margin="1rem"
                  display="flex"
                  alignItems="center"
                >
                  <Grid item xs={isDesktop ? 12 : 10} p={1}>
                    <AvatarUpload
                      defaultValue={credentials?.avatar}
                      register={register}
                      name="avatar"
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid item xs={12} p={1}>
                    <ControlledInput
                      required
                      fullWidth
                      type="text"
                      id="name"
                      label="Driver's full name"
                      name="name"
                      autoFocus
                    />{' '}
                  </Grid>{' '}
                  <Grid item xs={12} p={1}>
                    <ControlledInput
                      required
                      fullWidth
                      type="number"
                      id="phoneNumber"
                      label="Driver's phone number"
                      name="phoneNumber"
                    />
                  </Grid>{' '}
                  <Grid item xs={12} p={1}>
                    <ControlledInput
                      required
                      fullWidth
                      sx={{
                        '.MuiOutlinedInput-root': {
                          padding: 0,
                        },
                      }}
                      name="password"
                      label="Password"
                      type="text"
                      id="password"
                      inputProps={{
                        maxLength: 20,
                        minLength: 6,
                      }}
                    />
                  </Grid>{' '}
                </Grid>
              </>
            ),
          },
          {
            label: 'Handling Info',
            component: (
              <>
                {/* <Typography variant="h3"> Handling Size</Typography>
                <Typography variant="body1">
                  Select the handling size for this driver
                </Typography> */}
                {/* {hanlingSizes.map(({ description, dimensions, price }) => (
                  <Grid item xs={12} p={1} key={description}>
                    <HandlingSizeCard
                      description={description}
                      dimensions={dimensions}
                      price={price}
                      radioComponent={
                        <ControlledRadio
                          control={formMethods.control}
                          name="hanlingSizes"
                          size="medium"
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                ))} */}
                <Grid item xs={12} p={1} mt={1}>
                  <ControlledSelect
                    fullWidth
                    id="handledCategory"
                    name="handledCategory"
                    label="Handled Category"
                    control={control}
                    defaultValue={'Parcel' || ''}
                    variant="outlined"
                  >
                    {['Parcel', 'Refrigerated']?.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </Grid>
              </>
            ),
          },
          {
            label: 'Cover Cities',
            component: (
              <>
                <List
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    ...(!isDesktop && { padding: 0 }),
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Available cities:
                    </ListSubheader>
                  }
                >
                  {cities &&
                    cities.map((city) => (
                      <CityCard
                        credentials={credentials}
                        setCredentials={setCredentials}
                        key={city._id}
                        city={city}
                      />
                    ))}
                </List>
                <Box display="flex" flexDirection="row-reverse" />
              </>
            ),
          },
        ]}
      />
    </Grid>
  );
};

export default DriverForm;
