import { gql } from '@apollo/client';

export const GET_CITIES = gql`
  query {
    getAllCities {
      cities {
        _id
        cityName
        updatedAt
        insideAreas {
          areaName
        }
        remoteAreas {
          areaName
        }
      }
      status
      message
    }
  }
`;
