import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';

import { formatDistance } from 'date-fns';
import { UserContext } from 'src/contexts/UserContext';
import { useNavigate } from 'react-router';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`,
);

const HeaderNotifications = () => {
  const { currentCourrier } = useContext(UserContext);

  const returnedFees = currentCourrier?.courrier?.deliveryFees.filter(
    (el) => el.status === 'Returned',
  );
  const navigate = useNavigate();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={returnedFees?.length}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2 }} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">
            {returnedFees?.length
              ? 'Your request has been returned for correction'
              : 'There are currently no notifications'}
          </Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          {returnedFees?.map((returnedFee) => (
            <ListItem
              sx={{
                p: 2,
                minWidth: 350,
                display: { xs: 'block', sm: 'flex' },
                cursor: 'pointer',
              }}
              onClick={() => navigate('/management/profile/details')}
            >
              <Box flex="1">
                <Box display="flex" justifyContent="space-between">
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {returnedFee.sizeCategory.name} Category
                  </Typography>
                  <Typography variant="caption" sx={{ textTransform: 'none' }}>
                    {formatDistance(new Date(returnedFee.updatedAt), new Date(), {
                      addSuffix: true,
                    })}
                  </Typography>
                </Box>
                <Typography component="span" variant="body2" color="text.secondary">
                  {' '}
                  {returnedFee.returnMessage}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default HeaderNotifications;
