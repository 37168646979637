import axios from 'axios';
const token = localStorage.getItem('token');

export const COURRIER_BACKEND_API = axios.create({
  headers: {
    authorization: token ? `Bearer ${token}` : '',
  },
  baseURL:
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? process.env.REACT_APP_REST_API
      : 'http://localhost:3050/API',
});
